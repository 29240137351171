/*****
 *
 * cookie
 *
 *****/
$(function(){
    $("#privacy-accept").on("close.bs.alert", function () {
        Cookies.set("privacy-accept", 1, { expires : 365 });
    });
});


/*****
 *
 * MODAL show > cookie-like
 *
 *****/
// $(function () {
//     websiteStorage = window.sessionStorage;
//     var dreamsViewed = websiteStorage.getItem('dreamsViewed');
//     if (dreamsViewed != "1") {
//         $('#dreamsModal').modal('show');
//         $('#dreamsModal').on('hidden.bs.modal', function () {
//             websiteStorage.setItem('dreamsViewed', 1);
//         });
//     }
// });